import React,{useState} from 'react';
import {Api} from '../api/Api';

export const useQuestionnaire = () => {
    const [isLoading,setIsLoading] = useState(false);
    const SaveQuestionnaire = async (visitor_parameters,question_data,message_type,data,query_string,form_data) => {
      
        setIsLoading(true);
        const response = await Api.post("api/v1/data-ingestion-pipeline", {
            visitor_parameters,
            question_data,
            message_type,
            data,
            query_string,
            form_data
        });
        setIsLoading(false);
        return response;
    }
    return {
        isLoading,
        SaveQuestionnaire
    }
}