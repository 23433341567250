import React from "react";
import Modal from "react-bootstrap/Modal";

function ComplaintProcedure({ show, onClose }) {
  return (
    <>
      <Modal show={show} onHide={onClose} size="lg" className="doc-modal">
        <Modal.Header>
          <Modal.Title
            as={"h3"}
            style={{
              fontWeight: 700,
              textTransform: "initial",
            }}
          >
            Complaint procedure
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <p>
              We always want to provide the best possible service for our
              customers but recognise that sometimes you might be disappointed
              with our service. If you are dissatisfied with the services that
              we have provided to you, you can make a complaint to us. By making
              a complaint, you’re giving us the opportunity to investigate and
              improve our services for everybody.
            </p>
            <p>
              To make a complaint, please contact us using the information
              provided in this procedure. We’ll then review your complaint and
              provide you with our response. Then, if you are not satisfied with
              the response we have provided, you can escalate the complaint to
              an Ombudsman for their decision.
            </p>
            <h2>
              <strong>How to make a complaint </strong>
            </h2>
            <p>
              If you wish to make a complaint about our services, you can do so
              by any reasonable means. To contact us, you can use the following
              details:
            </p>
            <p>
              By Phone: <a href="tel:0800 999 7440">0800 999 7440</a>
            </p>
            <p>
              By Email:{" "}
              <a href="mailto:complaints@onlinelegallimited.com">
                complaints@onlinelegallimited.com
              </a>
            </p>
            <p>
              By Mail or in Person: Unit 2, Connect Business Village, 24 Derby
              Road, Liverpool, L5 9PR
            </p>
            <h2>
              <strong>Information to provide when making a complaint </strong>
            </h2>
            <p>
              We aim to resolve your complaint as quickly as possible. To help
              us do this, please provide the following when making your
              complaint:
            </p>
            <ol className="mx-3">
              <li>A description of your concern</li>
              <li>What you would like us to do to put things right</li>
              <li>Your name and address</li>
              <li>A phone number and the best time to contact you</li>
            </ol>
            <h2>
              <strong>What happens after making a complaint</strong>
            </h2>
            <h4>Complaints resolved within 3 days</h4>
            <p>
              If we can resolve your complaint within 3 business days from the
              date that we received your complaint, we will write to you
              confirming that the complaint has been resolved and provide you
              with a Summary Resolution Communication. We will only consider
              your complaint resolved within this timeframe when you indicate
              acceptance of our response.
            </p>
            <h4>Complaints not resolved within 3 days</h4>
            <p>
              If we cannot resolve your complaint within three business days
              from the date it has been received, will issue a written
              acknowledgement letter to you promptly and no later than 3-5
              working days following the date we received your complaint. Our
              acknowledgement letter will provide you with the early assurance
              that we have received the complaint and that we are dealing with
              it. Following the issuance of the acknowledgement letter, we will
              keep you informed thereafter regarding the progress of the
              measures that are being taken to resolve the complaint.
            </p>
            <p>
              Our Complaint Handler will then review your complaint and may need
              to contact you to seek additional information or clarifications to
              ascertain the facts surrounding your complaint. Your concerns will
              then be fully investigated by our complaint handler.
            </p>
            <p>
              Following our investigations, we will issue you a final response
              letter within 8 weeks of the date that we received your complaint.
              The final response will detail our findings and determination.
              Where we are unable to provide you with a final response within
              the 8 weeks following the date that we received your complaint, we
              will issue you with a written response which will explain why we
              are not yet in a position to provide you with a final response,
              when you can expect to receive a final response and explain that
              you may refer the complaint to the Financial Ombudsman Service
              (specifically the Claims Management Ombudsman, a division within
              the Financial Ombudsman Service).
            </p>
            <h2>
              <strong>
                The financial ombudsman service (claims management ombudsman)
              </strong>
            </h2>
            <p>
              If you are not satisfied with our resolution of your complaint
              i.e., where you receive a Summary Resolution Communication, final
              response, or you have received a written response and have not
              received a final response within the 8-week timeframe, you have
              the right to refer your complaint to the Financial Ombudsman
              Service (Claims Management Ombudsman), free of charge – but you
              must do so within six months of the date of this letter.
            </p>
            <p>
              If you do not refer your complaint in time, the Ombudsman will not
              have our permission to consider your complaint and so will only be
              able to do so in very limited circumstances. For example, if the
              Ombudsman believes that the delay was as a result of exceptional
              circumstances.
            </p>
            <p>
              Website |{" "}
              <a href="https://cmc.financial-ombudsman.org.uk/">
                https://cmc.financial-ombudsman.org.uk/
              </a>
            </p>
            <p>
              Address | Financial Ombudsman Service, Exchange Tower, London, E14
              9SR
            </p>
            <p>
              Freephone | <a href="tel:0800 0234 567">0800 0234 567</a>
            </p>
            <p>
              Standard Telephone | <a href="tel:0300 1239 123">0300 1239 123</a>
            </p>
            <p>
              Outside of the UK |{" "}
              <a href="tel:+44 20 7954 050">+44 20 7954 0500</a>
            </p>
            <p>
              Financial Ombudsman Service’s Explanatory Leaflet:{" "}
              <a href="https://www.financial-ombudsman.org.uk/businesses/resolving-complaint/ordering-leaflet/leaflet">
                https://www.financial-ombudsman.org.uk/businesses/resolving-complaint/ordering-leaflet/leaflet
              </a>
            </p>
            <p>
              You can find more information on the services provided by the
              Financial Ombudsman Service by visiting their website at{" "}
              <a href="https://www.financial-ombudsman.org.uk/">
                https://www.financial-ombudsman.org.uk/
              </a>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            data-dismiss="modal"
            onClick={onClose}
            style={{ backgroundColor: "#f0f0f0", color: "black" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ComplaintProcedure;
