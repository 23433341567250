import React from "react";
import Modal from "react-bootstrap/Modal";

const tdStyle = {
  maxWidth: "300px",
  wordBreak: "break-word",
};

function CookiePolicyModal({ show, onClose }) {
  return (
    <>
      <Modal show={show} onHide={onClose} size="xl" className="doc-modal">
        <Modal.Header>
          <Modal.Title
            as={"h3"}
            style={{
              fontWeight: 700,
              textTransform: "initial",
            }}
          >
            Cookie Policy
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12"
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  <div>
                    <h2 id="categories-of-cookies-in-use">
                      Categories of cookies in use
                    </h2>
                    <p>
                      The categories of cookies in use are described as follows:
                    </p>

                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th>
                              <strong>Category</strong>
                            </th>
                            <th>
                              <strong>Description of Category</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={tdStyle}>Advertisement</td>
                            <td style={tdStyle}>
                              Functional cookies help to perform certain
                              functionalities like sharing the content of the
                              website on social media platforms, collecting
                              feedback, and other third-party features.
                            </td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              Analytics cookies are used to understand how
                              visitors interact with the website. These cookies
                              help provided information on metrics such as the
                              number of visitors, bounce rate, traffic source
                              etc.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 id="cookies-in-use">Cookies in use</h2>
                    <p>
                      Cookies are small text files that are placed on your
                      computer by websites that you visit. They are widely used
                      in order to make websites work, or work more efficiently,
                      as well as to provide information to the owners of the
                      site. The table below explains the cookies we use and why.
                    </p>

                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead>
                          <tr>
                            <th>Cookie</th>
                            <th>Domain</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Expiry</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={tdStyle}>_fbp</td>
                            <td style={tdStyle}>
                              .housingdisrepairclaim.co.uk
                            </td>
                            <td style={tdStyle}>Advertisement</td>
                            <td style={tdStyle}>
                              Facebook Pixel advertising first-party cookie.
                              Used by Facebook to track visits across websites
                              to deliver a series of advertisement products such
                              as real-time bidding from third-party advertisers.
                            </td>
                            <td style={tdStyle}>3 Months</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>uuid</td>
                            <td style={tdStyle}>.adnxs.com</td>
                            <td style={tdStyle}>Advertisement</td>
                            <td style={tdStyle}>
                              MediaMath uses this cookie to identify a unique
                              visitor. It is used to optimize ad relevance by
                              collecting visitor data from multiple websites. It
                              does not contain information that is reasonably
                              linkable to an identifiable person.
                            </td>
                            <td style={tdStyle}>Session</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>_gcl_au</td>
                            <td style={tdStyle}>
                              .housingdisrepairclaim.co.uk
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              Provided by Google Tag Manager to experiment
                              advertisement efficiency of websites using their
                              services.
                            </td>
                            <td style={tdStyle}>3 months</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>_vwo_uuid_v2</td>
                            <td style={tdStyle}>
                              <p />
                              <p>.pre.start.housingdisrepairclaim.co.uk</p>
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              Calculates Unique Traffic On a website
                            </td>
                            <td style={tdStyle}>1 Year</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>_ga</td>
                            <td style={tdStyle}>
                              <p />
                              <p>.housingdisrepairclaim.co.uk</p>
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              Contains a unique identifier used by Google
                              Analytics to determine that two distinct hits
                              belong to the same user across browsing sessions.
                            </td>
                            <td style={tdStyle}>1 Year</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>_ga_*</td>
                            <td style={tdStyle}>
                              <p />
                              <p>.housingdisrepairclaim.co.uk</p>
                              <p />
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              Contains a unique identifier used by Google
                              Analytics 4 to determine that two distinct hits
                              belong to the same user across browsing sessions.
                            </td>
                            <td style={tdStyle}>1 Year</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>ip_address</td>
                            <td style={tdStyle}>
                              <p>.housingdisrepairclaim.co.uk</p>
                              <p />
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              This cookie collects the address of the IP office
                              used by the device. The information will help us
                              improve our marketing in the future.
                            </td>
                            <td style={tdStyle}>Session</td>
                          </tr>
                          <tr>
                            <td style={tdStyle}>split_name</td>
                            <td style={tdStyle}>
                              <p>.housingdisrepairclaim.co.uk</p>
                              <p />
                            </td>
                            <td style={tdStyle}>Analytics</td>
                            <td style={tdStyle}>
                              The name of the split that purchased the
                              impressions in this data set. Splits are only
                              applicable to augmented line items.
                            </td>
                            <td style={tdStyle}>Session</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 id="how-to-change-cookie-settings">
                      How to change cookie settings
                    </h2>
                    <p>
                      Most web browsers allow some control of the use of
                      cookies. You can access these controls through your
                      browser’s settings. You can find out more about managing
                      cookies on mainstream browsers by navigating through the
                      following links:
                    </p>
                    <ul>
                      <li>
                        <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en">
                          Google Chrome
                        </a>
                      </li>
                      <li>
                        <a href="https://support.microsoft.com/en-us/search?query=enable%20cookies%20in%20edge">
                          Microsoft Edge
                        </a>
                      </li>
                      <li>
                        <a href="https://help.opera.com/en/latest/web-preferences/">
                          Opera
                        </a>
                      </li>
                      <li>
                        <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">
                          Mozilla Firefox
                        </a>
                      </li>
                      <li>
                        <a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
                          Apple Safari
                        </a>{" "}
                      </li>
                    </ul>
                    <p>
                      To find out more information about alternative browsers,
                      please visit the relevant browser’s website.{" "}
                    </p>
                    <p>
                      To opt-out of being tracked by Google Analytics across all
                      websites, visit{" "}
                      <a href="http://tools.google.com/dlpage/gaoptout">
                        http://tools.google.com/dlpage/gaoptout
                      </a>
                      .{" "}
                    </p>
                    <h2 id="more-information-on-cookies-">
                      More information on cookies.
                    </h2>
                    <p>
                      If you would like to find out more about cookies, you can
                      visit the following websites:
                    </p>
                    <ul>
                      <li>
                        <a href="http://www.aboutcookies.org">
                          www.aboutcookies.org
                        </a>
                      </li>
                      <li>
                        <a href="http://www.allaboutcookies.org">
                          www.allaboutcookies.org
                        </a>
                      </li>
                    </ul>
                    <p>Date: 20/06/2023</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            data-dismiss="modal"
            onClick={onClose}
            style={{ backgroundColor: "#f0f0f0", color: "black" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookiePolicyModal;
