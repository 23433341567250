import { useCallback } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonPhoneValidation } from "../api/Api";

const phoneErrors = [
  {
    status_code: 0,
    message: "Please Enter Valid Working Phone Number",
  },
  {
    status_code: 2,
    message: "Phone Number Already Exist",
  },
  {
    status_code: 3,
    message: "Please Enter Valid Phone Number",
  },
];

export const usePhoneValidation = () => {
  const phoneValidation = useCallback(async (phone) => {
    const response = await CommonPhoneValidation.post("/api/validate", {
      ProjectCode: EnvConstants.AppAdtopiaUPID,
      Environment: EnvConstants.AppEnv,
      Phone: phone,
    });

    return response;
  }, []);

  const validatePhone = useCallback(
    async (phone) => {
      if (!phone) return;

      const { data } = await phoneValidation(phone);
      if (!data) return;
      if (data.response != 200) return;

      const error = phoneErrors.find(
        (e) => e.status_code === data?.status_code
      );
      return error?.message;
    },
    [phoneValidation]
  );

  return { phoneValidation, validatePhone };
};
