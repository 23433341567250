import React from "react";
import { Helmet } from "react-helmet-async";

function CookieBotBanner(props) {
  return (
    <Helmet>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="c55614ed-9062-4d1a-afc5-23c2c87a8d8d"
        data-blockingmode="auto"
        type="text/javascript"
      ></script>
    </Helmet>
  );
}

export default CookieBotBanner;
