import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";

function CookieBotPolicyModal({ show, onClose }) {
  useEffect(() => {
    if (show != true) return;

    if (document.getElementById("CookieDeclaration")) return;

    const parent = document.getElementById("cookie-modal-content");
    if (!parent)
      return console.error("Cookie Policy Modal: Parent element not found");

    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src =
      "https://consent.cookiebot.com/c55614ed-9062-4d1a-afc5-23c2c87a8d8d/cd.js";
    script.async = true;
    script.type = "text/javascript";
    parent.appendChild(script);
  }, [show]);

  return (
    <>
      <Modal show={show} onHide={onClose} size="xl" className="doc-modal">
        <Modal.Header>
          <Modal.Title
            as={"h3"}
            style={{
              fontWeight: 700,
              textTransform: "initial",
            }}
          >
            Cookie Policy
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <section id="cookie-modal-content"></section>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            data-dismiss="modal"
            onClick={onClose}
            style={{ backgroundColor: "#f0f0f0", color: "black" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookieBotPolicyModal;
