import React, { useState } from "react";
import logo from "../../../../assets/MN_V1/img/logo.png";
import ComplaintProcedure from "./ComplaintProcedure";
import CookieBotBanner from "./CookieBotBanner";
import CookieBotPolicyModal from "./CookieBotPolicyModal";
import PrivacyPolicy from "./PrivacyPolicy";

const MODALS = {
  PRIVACY: "privacy",
  COMPLAINT: "complaint",
  COOKIE: "cookie",
};

const Footer = ({ contactNeeded }) => {
  const [modal, setModal] = useState(null);

  return (
    <footer className="py-4 ">
      <PrivacyPolicy
        show={modal === MODALS.PRIVACY}
        onClose={() => setModal(null)}
        showCookiePolicy={() => setModal(MODALS.COOKIE)}
      />

      <ComplaintProcedure
        show={modal === MODALS.COMPLAINT}
        onClose={() => setModal(null)}
      />

      <CookieBotPolicyModal
        show={modal === MODALS.COOKIE}
        onClose={() => setModal(null)}
      />

      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10">
            <div className="text-center py-2">
              <div className="mb-3">
                <a href="/" className="ft-logo">
                  <img src={logo} alt="logo" className="logo" />
                </a>
              </div>
              <p>
                This website is owned and operated by Online Legal Limited a
                regulated claims management company who are authorised and
                regulated by the Financial Conduct Authority in respect of
                claims management activities.
              </p>
              <p>Authorisation number: 835370</p>
              <p>
                This site is independent of the NHS and the Department of Health
                - We cover England and Wales only.
              </p>
              <p className="mb-0">
                © 2023 NHS Negligence Claim |{" "}
                <a href="#privacy" onClick={() => setModal(MODALS.PRIVACY)}>
                  Privacy Policy
                </a>{" "}
                |{" "}
                <a href="#complaint" onClick={() => setModal(MODALS.COMPLAINT)}>
                  Complaints Procedure
                </a>{" "}
                |{" "}
                <a href="#cookie" onClick={() => setModal(MODALS.COOKIE)}>
                  Cookie Policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <CookieBotBanner />
    </footer>
  );
};

export default Footer;
