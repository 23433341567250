import React from "react";
import review from '../../../../assets/img/review.gif';
const Loader = ({ showLoad }) => {
  return (
    <>
      <div id="myDiv" className={`space text-center ${showLoad}`}>
        <img src={review} alt="" style={{ width: '100px', marginTop: '20px' }} />
        <h3 className="flp-loader">Analysing your application....</h3>
      </div>
    </>
  );
};

export default Loader;
