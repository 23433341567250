import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import CookiePolicyModal from "./CookiePolicyModal";

function PrivacyPolicy({ show, onClose, showCookiePolicy }) {
  const [showModal, setShowModal] = useState("");

  return (
    <>
      <CookiePolicyModal
        show={showModal === "cookie"}
        onClose={() => setShowModal(null)}
      />

      <Modal show={show} onHide={onClose} size="lg" className="doc-modal">
        <Modal.Header>
          <Modal.Title
            as={"h3"}
            style={{
              fontWeight: "bolder",
              textTransform: "initial",
            }}
          >
            Privacy policy
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={onClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <section>
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12"
                  style={{ wordBreak: "break-word" }}
                >
                  <p>
                    Online Legal Limited&nbsp;(“We” and “our site”) are
                    committed to protecting and respecting your privacy.
                  </p>
                  <p>
                    This policy (together with our terms of use and any other
                    documents referred to on it) sets out the basis on which any
                    personal data we collect from you, or that you provide to
                    us, will be processed by us. Please read the following
                    carefully to understand our views and practices regarding
                    your personal data and how we will treat it.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Completion of Forms
                  </h2>
                  <p>
                    Any information submitted by you through any enquiry form
                    placed on our site and/or through any social media or other
                    online advertising channel may be sent directly to an
                    external law firm. All external law firms on our panel have
                    been chosen by us. By completing any form on our site you
                    consent to being contacted by us and/or the panel law firm
                    who has received your enquiry to discuss your matter
                    further.
                  </p>
                  <p>
                    You are under no obligation to choose to use the services
                    offered by our Panel Law Firms and are free to choose any
                    other legal representation you feel best matches your
                    individual needs.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Call Recording{" "}
                  </h2>
                  <p>
                    By completing any form on our site and/or through any social
                    media or other online advertising channel you consent to
                    being contacted by us and/or the panel law firm who has
                    received your enquiry to discuss your matter further by
                    telephone or electronic methods.
                  </p>
                  <p>
                    Please note all our calls may be recorded for training
                    purposes and to monitor customer service and regulatory
                    compliance. We may also share such recordings with our Panel
                    Law Firms for service and regulatory monitoring and you
                    consent to such sharing by submitting any form on this
                    site&nbsp;nd/or through any social media or other online
                    advertising channel.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Information we may collect from you
                  </h2>
                  <p>
                    <strong>
                      We may collect and process the following data about you:
                    </strong>
                  </p>
                  <ul style={{ fontWeight: 400 }}>
                    <li style={{ marginBottom: 10 }}>
                      Information that you provide by filling in forms on our
                      site (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.nhsnegligenceclaim.com"
                      >
                        https://www.nhsnegligenceclaim.com
                      </a>
                      ). This includes information provided at the time of
                      registering to use our site, subscribing to our service,
                      posting material or requesting further services. We may
                      also ask you for information when you enter a competition
                      or promotion, and when you report a problem with our site.
                    </li>
                    <li style={{ marginBottom: 10 }}>
                      If you contact us, we may keep a record of that
                      correspondence. We may also ask you to complete surveys
                      that we use for research purposes, although you do not
                      have to respond to them.
                    </li>
                    <li style={{ marginBottom: 10 }}>
                      Details of your visits to our site including, but not
                      limited to, traffic data, location data, weblogs and other
                      communication data, whether this is required for our own
                      billing purposes or otherwise and the resources that you
                      access.
                    </li>
                  </ul>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Call Recording
                  </h2>
                  <p>
                    The phone calls we have with you may be recorded for quality
                    assurance, regulatory compliance and customer service
                    purposes. These recordings may also be shared with our Panel
                    Law Firms for regulatory monitoring.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Your Data Protection Rights
                  </h2>
                  <p>
                    {" "}
                    Every user of an Online Legal website is entitled to the
                    following:{" "}
                  </p>
                  <p>
                    <b>The right to access</b> – You have the right to request
                    for copies of your personal data.
                  </p>
                  <p>
                    <b>The right to rectification</b> – You have the right to
                    request that correct any information you believe is
                    inaccurate. You also have the right to request to complete
                    the information you believe is incomplete.
                  </p>
                  <p>
                    <b>The right to erasure</b> – You have the right to request
                    that we erase your personal data, under certain conditions.
                  </p>
                  <p>
                    <b>The right to restrict processing</b> – You have the right
                    to request that we restrict the processing of your personal
                    data, under certain conditions.
                  </p>
                  <p>
                    <b>The right to object to processing</b> – You have the
                    right to object to our processing of your personal data,
                    under certain conditions.
                  </p>
                  <p>
                    <b>The right to data portability</b> – You have the right to
                    request that we transfer the data that we have collected to
                    another organization, or directly to you, under certain
                    conditions.
                  </p>
                  <p>
                    If you make a request, we have one month to respond to you.
                    If you would like to exercise any of these rights, please
                    contact us at our email:{" "}
                    <a href="mailto:dataprotection@onlinelegallimited.com">
                      dataprotection@onlinelegallimited.com
                    </a>
                  </p>
                  <p>
                    <b>Call us at:</b> 0800 999 3372
                  </p>
                  <p>
                    <b>Or write to us:</b> Unit 2, 24 Derby Road, Liverpool, L5
                    9PR
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    IP addresses
                  </h2>
                  <p>
                    We may collect information about your computer, including
                    where available your IP address, operating system and
                    browser type, for system administration and to report
                    aggregate information to our advertisers. This is
                    statistical data about our users’ browsing actions and
                    patterns, and does not identify any individual.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Cookies &amp; 3rd Party Pixels&nbsp;
                  </h2>
                  <p>
                    We use cookies and 3rd party tracking pixels, including
                    Facebook and Google, who may use cookies, web beacons and
                    other storage technologies to collect or receive information
                    from our websites and elsewhere on the Internet and use that
                    information to provide measurement services and target ads,
                    you can opt out of the collection and use of information for
                    ad targeting with further details below, and &nbsp;you can
                    access a mechanism for exercising such choice by visiting{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://optout.aboutads.info/?c=2&lang=EN"
                    >
                      http://www.aboutads.info/choices
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.youronlinechoices.eu/"
                    >
                      http://www.youronlinechoices.eu
                    </a>
                    .
                  </p>
                  <p>
                    Our website uses cookies to distinguish you from other users
                    of our website. This helps us to provide you with a good
                    experience when you browse our website and also allows us to
                    improve our site. For detailed information on the cookies we
                    use and the purposes for which we use them see our{" "}
                    <a href="#" onClick={showCookiePolicy}>
                      Cookie policy
                    </a>
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Where we store your personal data
                  </h2>
                  <p>
                    The data that we collect from you may be transferred to, and
                    stored at, a destination outside the European Economic Area
                    (“EEA”). It may also be processed by staff operating outside
                    the EEA who work for us or for one of our suppliers. Such
                    staff maybe engaged in, among other things, the fulfilment
                    of your order, the processing of your payment details and
                    the provision of support services. By submitting your
                    personal data, you agree to this transfer, storing or
                    processing. We will take all steps reasonably necessary to
                    ensure that your data is treated securely and in accordance
                    with this privacy policy.
                  </p>
                  <p>
                    Unfortunately, the transmission of information via the
                    internet is not completely secure. Although we will do our
                    best to protect your personal data, we cannot guarantee the
                    security of your data transmitted to our site; any
                    transmission is at your own risk. Once we have received your
                    information, we will use strict procedures and security
                    features to try to prevent unauthorised access.
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Uses made of the information
                  </h2>
                  <p>
                    <strong>
                      We use information held about you in the following ways:
                    </strong>
                  </p>
                  <ul className="list_space" style={{ fontWeight: 400 }}>
                    <li>
                      To ensure that content from our site is presented in the
                      most effective manner for you and for your computer.
                    </li>
                    <li>
                      To provide you with information, products or services that
                      you request from us or which we feel may interest you,
                      where you have consented to be contacted for such
                      purposes.
                    </li>
                    <li>
                      To carry out our obligations arising from any contracts
                      entered into between you and us.
                    </li>
                    <li>
                      To allow you to participate in interactive features of our
                      service, when you choose to do so.
                    </li>
                    <li>To notify you about changes to our service.</li>
                    <li>
                      We may also use your data, or permit selected third
                      parties to use your data, to provide you with information
                      about goods and services which may be of interest to you
                      and we or they may contact you about these by email, post
                      or telephone.
                    </li>
                  </ul>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Disclosure of your information
                  </h2>
                  <p>
                    We may disclose your personal information to any member of
                    our group, which means our subsidiaries, our ultimate
                    holding company and its subsidiaries, as defined in section
                    1159 of the UK Companies Act 2006.
                  </p>
                  <p>
                    <strong>
                      We may disclose your personal information to third
                      parties:
                    </strong>
                  </p>
                  <ul className="list_space" style={{ fontWeight: 400 }}>
                    <li>
                      In the event that we sell or buy any business or assets,
                      in which case we may disclose your personal data to the
                      prospective seller or buyer of such business&nbsp;or
                      assets.
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.nhsnegligenceclaim.com/"
                      >
                        https://www.nhsnegligenceclaim.com
                      </a>{" "}
                      or substantially all of its assets are acquired by a third
                      party, in which case personal data held by it about its
                      customers will be one of the transferred assets.
                    </li>
                    <li>
                      If we are under a duty to disclose or share your personal
                      data in order to comply with any legal obligation, or in
                      order to enforce or apply our terms of use and other
                      agreements; or to protect the rights, property, or safety
                      of our customers, or others. This includes exchanging
                      information with other companies and organisations for the
                      purposes of fraud protection and credit risk reduction.
                    </li>
                  </ul>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Regulation
                  </h2>
                  <p>
                    This website is owned and operated by Online Legal Limited
                    who are authorised and regulated by the Financial Conduct
                    Authority in respect of regulated claims management
                    activities.
                  </p>
                  <p>
                    <b>Authorisation Number: 835370</b>
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Your rights
                  </h2>
                  <p>
                    You have the right to ask us not to process your personal
                    data for marketing purposes. We will usually inform you
                    (before collecting your data) if we intend to use your data
                    for such purposes or if we intend to disclose your
                    information to any third party for such purposes. You can
                    exercise the right at any time by contacting us at{" "}
                    <a href="mailto:dataprotection@onlinelegallimited.com">
                      dataprotection@onlinelegallimited.com
                    </a>
                  </p>
                  <p>
                    Our site may, from time to time, contain links to and from
                    the websites of our partner networks, advertisers and
                    affiliates. If you follow a link to any of these websites,
                    please note that these websites have their own privacy
                    policies and that we do not accept any responsibility or
                    liability for these policies. Please check these policies
                    before you submit any personal data to these websites.
                    ”Submit” shall include any and all enquiry submissions made
                    through&nbsp;
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.nhsnegligenceclaim.com/"
                    >
                      https://www.nhsnegligenceclaim.com
                    </a>
                  </p>
                  <h2
                    className="clr"
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    How to contact the appropriate authority
                  </h2>
                  <p>
                    Should you wish to report a complaint or if you feel that
                    Online LegaL Limited has not addressed your concern in a
                    satisfactory manner, you may contact the Information
                    Commissioner’s Office.
                  </p>
                  <p>
                    <b>Call:</b> 0303 123 1113
                  </p>
                  <p>
                    <b>Address:</b> Information Commissioner’s Office, Wycliffe
                    House, Water Lane, Wilmslow, Cheshire, SK9 5AF
                  </p>
                  <p>
                    The ICO Registration Number for Online Legal Limited is
                    Registration number: ZA187055
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            data-dismiss="modal"
            onClick={onClose}
            style={{ backgroundColor: "#f0f0f0", color: "black" }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyPolicy;
