import React, { useEffect, useState, useRef, useReducer,useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BasicQuestionnaireOne from "../Includes/Layouts/Split_1/BasicQuestionnaireOne";
import BasicQuestionnaireTwo from "../Includes/Layouts/Split_1/BasicQuestionnaireTwo";
import BasicQuestionnaireThree from "../Includes/Layouts/Split_1/BasicQuestionnaireThree";
import BasicQuestionnaireFour from "../Includes/Layouts/Split_1/BasicQuestionnaireFour";
import PersonalDetails from "../Includes/Layouts/Split_1/PersonalDetails";
import PostCode from "../Includes/Layouts/Split_1/PostCode";
import ContactDetails from "../Includes/Layouts/Split_1/ContactDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";

const FormSplit_1 = (props) => {
 
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "question_1") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "bank") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_3") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_4") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "five") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "six") {
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "address") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "joint_slide") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case 'previousSlides': {
        if (action.payload.previousSlide.slide == 'back01') {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back02') {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide"
          }
        } else if (action.payload.previousSlide.slide == 'back03') {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide"
          }
        }else if (action.payload.previousSlide.slide == 'back04') {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide"
          }
        }else if (action.payload.previousSlide.slide == 'back05') {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide"
          }
        }else if (action.payload.previousSlide.slide == 'back06') {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide"
          }
        }
        else {
          return {
            ...state
          }
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;  

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    formData.page_name = 'Split_1';
    formData.pCode_manual = form['pCode_EntryType'].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString',queryString );
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'Split_1',
        queryString,
        visitorParameters.data
      )
      if (formSUbmitResult.data.status === "Success") {

        if (queryString != null) {
          console.log("first if");
          console.log(queryString);
          if (queryString.indexOf("&" + field + "=") !== -1) {
            console.log("s");
            if(EnvConstants.AppConversionPixel=== 'true'){
              console.log("if fb");
              history.push("/fbpixel?split_name=" + props.splitName +"&next_page=" + props.nextPage ); // fb fixel firing
            }else{
              console.log("else fb");
              window.scrollTo(0, 0);
              history.push(
                "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
              );
            } 
          } else {
            console.log("another else fb");
            window.scrollTo(0, 0);
            history.push(
              "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
            );
          }
        } else {

          console.log("laset else  fb");
          window.scrollTo(0, 0);
          history.push(
            "/" + props.nextPage + "?uuid=" + visitorParameters.visitor_parameters.uuid + "&splitName="+ props.splitName
          );
        }
      }
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
        <BasicQuestionnaireOne
          className={`form-group text-center ${state.showSlide1}`}
          //textHeading="Are you currently enrolled in Medicare?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
        />
        <BasicQuestionnaireTwo
          className={`form-group text-center ${state.showSlide2}`}
          textHeading="Are you receiving benefits from the MediCal program?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          backClick={previousSlideChange}
        />
        <BasicQuestionnaireThree
          className={`form-group text-center ${state.showSlide3}`}
          // textHeading="Are you currently enrolled in Medicare?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          backClick={previousSlideChange}
        />
        <BasicQuestionnaireFour
          className={`form-group text-center ${state.showSlide4}`}
          // textHeading="Are you currently enrolled in Medicare?"
          validation={register}
          slideChange={slideChange}
          getValues={getValues}
          backClick={previousSlideChange}
        />
        <PersonalDetails
          className={`${state.showSlide5}`}
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          getValues={getValues}
          splitForm={splitForm}
          slideChange={slideChange}
          slideChangeAddress={slideChangeAddress}
          backClick={previousSlideChange}
          setError={setError}
        />
        <PostCode
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          className={`${state.showSlide6}`}
          getValues={getValues}
          splitForm={splitForm}
          slideChange={slideChange}
          backClick={previousSlideChange}
        />
        <ContactDetails
          validation={register}
          validationMsg={errors}
          trigger={trigger}
          clearErrors={clearErrors}
          setError={setError}
          className={`${state.showSlide7}`}
          getValues={getValues}
          formSubmit={formSubmit}
          backClick={previousSlideChange}
        />
        </div>
      </form>
    </>
  );
};

export default FormSplit_1;
