import React, { useEffect } from "react";
import logo from "../../assets/MN_V1/img/logo.png";
import "../../assets/Thankyou/css/Thankyou.scss";
import imgThumb from "../../assets/Thankyou/img/thumb.png";
import Footer from "../Includes/Layouts/MN_V1/Footer";

const Thankyou = () => {
  useEffect(() => {
    localStorage.clear();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  });

  return (
    <div className="thankyou">
      <header className="p-2 bg-white  " id="page-top">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </div>
        </div>
      </header>

      <section>
        <div className="container">
          <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 text-center">
            <img src={imgThumb} alt="" className="img-fluid mb-3" />

            <h5 className="mb-3">THANK YOU!</h5>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Thankyou;
