import { useCallback } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import { CommonEmailValidateApi } from "../api/Api";

export const useEmailValidation = () => {
  const emailValidation = useCallback(async (email) => {
    const getValidation = await CommonEmailValidateApi.post(
      "api/validate?ProjectCode=" +
        EnvConstants.AppAdtopiaUPID +
        "&Environment=" +
        EnvConstants.AppEnv +
        "&Email=" +
        email,
      {}
    );

    return getValidation;
  }, []);

  const validateEmail = useCallback(
    async (email) => {
      if (!email) return;

      const { data } = await emailValidation(email);
      if (!data) return;

      if (data?.status_code != 1) {
        return data?.message;
      }
    },
    [emailValidation]
  );

  return { emailValidation, validateEmail };
};
